import Link from "components/common/ui/Link/Link";
import MenuItem, {
	MenuItemProps,
} from "components/common/ui/Menu/MenuItem/MenuItem";
import { HeadingMediumStyles } from "components/common/ui/Typography/Typography.Styled";
import { useDispatch } from "redux/hooks";
import { setActiveCategoryId } from "redux/UI/uiSlice";
import { css } from "styled-components";
import { dark, darkGrey } from "utilities/variables/colors";
import { SideMenuItemButton } from "../SideMenu.Styled";
import IconChevron from "components/common/ui/Icons/IconChevron/IconChevron";
import { FunctionComponent, useMemo } from "react";

const additionalStyles = css`
	margin-top: 24px;
	padding: 0px 20px;
	color: ${dark};
	text-transform: unset;

	&:first-of-type {
		margin-top: 0px;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	&:hover,
	&:active,
	.active {
		color: ${darkGrey};
	}
`;

const rootMenuStyles = css`
	${HeadingMediumStyles}
	${additionalStyles}
`;

const subMenuStyles = css`
	${HeadingMediumStyles}
	${additionalStyles}
	font-weight: 500;
	button {
		font-weight: 500;
		text-transform: unset;
	}

	&.see-all {
		font-weight: 700;
	}
`;

interface SideMenuItemProps {
	id: string | null;
	label: string;
	hasChildren?: boolean;
	path?: string;
	subMenu?: boolean;
}

const SideMenuItem: FunctionComponent<MenuItemProps & SideMenuItemProps> = ({
	id,
	label,
	hasChildren = false,
	path = "",
	onClick = () => {},
	subMenu,
}) => {
	const dispatch = useDispatch();

	const onLinkClick = (id: any) => {
		dispatch(setActiveCategoryId(id));

		if (onClick) {
			onClick();
		}
	};

	const isSeeAll = useMemo(() => {
		return label.toLowerCase() === "see all" ? "see-all" : undefined;
	}, [label]);

	if (!hasChildren) {
		return (
			<MenuItem
				additionalStyles={!subMenu ? rootMenuStyles : subMenuStyles}
				className={isSeeAll}
				onClick={() => {
					if (!sessionStorage.getItem("last_viewed_category")?.includes(path)) {
						sessionStorage.setItem("last_viewed_product", "{}");
					}
					onLinkClick(id);
				}}
			>
				{path ? <Link to={path}>{label}</Link> : <>{label}</>}
			</MenuItem>
		);
	} else {
		return (
			<MenuItem
				additionalStyles={!subMenu ? rootMenuStyles : subMenuStyles}
				className={isSeeAll}
				onClick={() => onClick()}
			>
				<SideMenuItemButton>
					{label}
					<IconChevron direction="right" className="forward" />
				</SideMenuItemButton>
			</MenuItem>
		);
	}
};

export default SideMenuItem;
