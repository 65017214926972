import { IonApp, IonHeader, IonToolbar, setupIonicReact } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import LocaleManager from "LocaleMananger";
import BcStoreManager from "app/bigcommerce/components/BcStoreManager";
import { init } from "app/i18n";
import WordPressManager from "app/wordpress/components/WordPressManager";
import EnvironmentNotification from "components/EnvironmentNotification/EnvironmentNotification";
import Header from "components/header/Header";
import Navbar from "components/nav/Navbar/Navbar";
import Routing from "components/routing/Routing";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "./theme/globalStyles";
import "./theme/variables.css";
import useIosTrackingConsent from "hooks/useIosTrackingConsent";


setupIonicReact();
init("en");

const App = () => {

	useIosTrackingConsent();

	return (
		<BrowserRouter>
			<IonApp>
				<GlobalStyles />
				<Routing />
				<IonHeader className="ion-no-border">
					<EnvironmentNotification />
					<IonToolbar>
						<Header />
						<Navbar />
					</IonToolbar>
				</IonHeader>
				<LocaleManager>
					<BcStoreManager>
						<WordPressManager />
					</BcStoreManager>
				</LocaleManager>
			</IonApp>
		</BrowserRouter>
	);
};

export default App;
