import { useLazyGetCategoryQuery } from "app/api/category/categoryApi";
import { pathsMatch } from "app/utils/urlUtils";
import ErrorContent from "components/common/ui/ErrorContent/ErrorContent";
import PlpDetails from "components/plp/PlpDetails/PlpDetails";
import PlpNavSort from "components/plp/PlpNavSort/PlpNavSort";
import PlpProductGrid from "components/plp/PlpProductGrid/PlpProudctGrid";
import useDataLayer from "datalayer/useDataLayer";
import useCategoryPagination from "hooks/useCategoryPagination";
import usePageContent from "hooks/usePageContent";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useMatch } from "react-router";
import { PageType } from "ts/enums";
import { Category, Product } from "ts/types";
import PathTranslationKey from "utilities/paths";
import Page from "../../components/common/ui/Page/Page";
import Button from "components/common/ui/Button/Button";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import useLoadingDebounce from "hooks/useLoadingDebounce";

interface State {
	layout: number;
	loading: boolean;
	category: Category | undefined;
	showNoProductsError: boolean;
	loadingNextSet: boolean;
}

const initialState: State = {
	layout: Number(window.localStorage.getItem("category_layout")) || 0,
	loading: true,
	category: undefined,
	showNoProductsError: false,
	loadingNextSet: false,
};

const LoadingSpinnerContainer = styled.div`
	margin-top: ${remCalc(32)};
`;

const ProductLandingPage = () => {
	const { debounceLoading, setLoading, loading } = useLoadingDebounce({
		initialValue: initialState.loading,
		delay: 400,
	});

	const reducer = (state: State, action: any): State => {
		switch (action.type) {
			case "layout_change":
				return { ...state, layout: action.payload };
			case "set_category":
				return { ...state, category: action.payload };
			case "set_loading":
				setLoading(action.payload);
				return { ...state, loading: action.payload };
			case "set_loading_next_set":
				return { ...state, loadingNextSet: action.payload };
			case "show_no_products_error":
				return { ...state, showNoProductsError: action.payload };
			default:
				return state;
		}
	};

	const { t } = useTranslation();
	const dataLayer = useDataLayer();
	const [state, dispatch] = useReducer(reducer, initialState);
	const [prevPath, setPrevPath] = useState("");

	const location = useLocation();
	const pagination = useCategoryPagination(state.category);
	const pathMatch = useMatch(`/:locale${t(PathTranslationKey.CATEGORY)}/*`);

	const [getCategory, getCategoryResult] = useLazyGetCategoryQuery();
	const resetPageScroll = usePageContent();

	useEffect(() => {
		/**
		 * This reset state data when the location
		 * changes from one category to another
		 */
		if (pathMatch) {
			if (!pathsMatch(prevPath, location.pathname)) {
				setPrevPath(location.pathname);
				dispatch({
					type: "set_loading",
					payload: true,
				});

				resetPageScroll.scrollToTop();

				const path = `/${pathMatch.params["*"]}`;
				getCategory(path, false);
			}
		}

		sessionStorage.setItem("last_viewed_category", location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		if (getCategoryResult.isSuccess) {
			const category = getCategoryResult.data;

			if (!category) {
				return;
			}

			if (state.category !== category) {
				dispatch({
					type: "set_category",
					payload: category,
				});
			}
		}
	}, [getCategoryResult]);

	useEffect(() => {
		if (!pagination.uninitialized && !pagination.loading) {
			dispatch({
				type: "set_loading",
				payload: false,
			});
			dispatch({
				type: "set_loading_next_set",
				payload: false,
			});

			if (category?.entityId && category?.name) {
				dataLayer.viewItemList(
					pagination.products,
					category.entityId,
					category.name,
				);
			}
		}
	}, [pagination.products]);

	useEffect(() => {
		if (loading !== pagination.loading) {
			setLoading(pagination.loading);
		}
	}, [pagination]);

	const loadingNextSet = state.loadingNextSet;
	const category = state.category;
	// if loading next set, don't show product grid skeletons - keep the existing products
	// this prevents the user from being scrolled to the top of the page
	const productGridLoading = loadingNextSet ? false : debounceLoading;
	const gridProducts = pagination.products;

	const showNoCategoryError =
		getCategoryResult.isSuccess && !getCategoryResult.data;

	const showNoProductsError =
		!pagination.uninitialized &&
		!debounceLoading &&
		!pagination.products.length;

	const title =
		(category?.seo.pageTitle ? category?.seo.pageTitle : category?.name) || "";

	const handleNextProductSetLoad = () => {
		dispatch({
			type: "set_loading_next_set",
			payload: true,
		});

		pagination.getNextProductSet();
	};

	const handleProductClick = (product: Product) => {
		localStorage.setItem("clickedId", product.id);
		if (category) {
			dataLayer.selectItem(product, category?.entityId, category?.name);
		}
	};
	return (
		<Page pageType={PageType.CATEGORY} title={title}>
			<PlpNavSort
				{...(state.category?.id && {
					categoryId: state.category?.entityId,
				})}
				layout={state.layout}
				dispatch={dispatch}
				breadcrumbs={category?.breadcrumbs}
				filters={pagination.filters}
				handleOnFilterAttributes={pagination.filterProductsByAttribute}
				handleOnFilterCategory={pagination.filterProductsByCategoryIds}
				handleOnClearFilters={pagination.clearFilters}
				handleOnFilterPrices={pagination.filterProductsByPrice}
				handleSortProducts={pagination.setSortOrder}
			/>

			{!loading && (
				<>
					{showNoCategoryError && (
						<ErrorContent heading={t("categoryNotFoundHeading")}>
							{t("categoryNotFound")}
						</ErrorContent>
					)}
					{!showNoCategoryError && showNoProductsError && (
						<ErrorContent heading={t("noProductsFoundHeading")}>
							{t("noProductsFound")}
						</ErrorContent>
					)}
				</>
			)}

			<PlpProductGrid
				layout={state.layout}
				loading={productGridLoading}
				breadcrumbs={category?.breadcrumbs}
				productsPerPage={48}
				category={category}
				products={gridProducts}
				onProductClick={handleProductClick}
			/>
			{!loadingNextSet && pagination.pagination?.hasNextPage ? (
				<Button
					variant="primary_01"
					margin={{
						ml: "auto",
						mr: "auto",
						mt: "32px",
					}}
					onClick={handleNextProductSetLoad}
				>
					{t("showMore")}
				</Button>
			) : loadingNextSet ? (
				<LoadingSpinnerContainer>
					<LoadingSpinner />
				</LoadingSpinnerContainer>
			) : null}
			<PlpDetails
				loading={loading}
				name={category?.name || ""}
				description={category?.description || ""}
			/>
		</Page>
	);
};

export default ProductLandingPage;
