import { Fragment, useEffect, useState } from "react";

import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { useRegisterMutation } from "app/api/customer/registerApi";

import AddressSection from "components/common/form/AddressSection/AddressSection";
import {
	InputEmail,
	InputPassword,
	InputPhoneNumber,
	InputText,
} from "components/common/form/Input/Input";
import InputCheckbox from "components/common/form/InputCheckbox/InputCheckbox";
import Alert, { AlertType } from "components/common/ui/Alert/Alert";
import Button from "components/common/ui/Button/Button";
import useLogin from "hooks/auth/useLogin";
import usePageContent from "hooks/usePageContent";
import IconEye from "components/common/ui/Icons/IconEye/IconEye";
import IconClosedEye from "components/common/ui/Icons/IconEye/IconClosedEye";

import {
	defaultValues,
	RegistrationFormScheme,
	registrationSchemeResolver,
} from "./RegistrationForm.Schema";

import {
	Form,
	FormSection,
	InputCol,
	InputRow,
	RegistrationFormLink,
	SectionText,
	SectionTitle,
	SigninSection,
} from "./RegistrationForm.Styled";

import useDataLayer from "datalayer/useDataLayer";
import useJoinLoyaltyForm from "hooks/useJoinLoyaltyForm";
import { capitalize } from "lodash";
import NewsLetterSubscribe from "./sections/NewsLetterSubscribe";

import { klaviyoApiEndpoints } from "app/api/klayivo/klayivoApi";
import useKlaviyo from "hooks/useKlaviyo";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";
import { useSelector } from "redux/hooks";

const RegistrationForm = () => {
	const form = useForm<RegistrationFormScheme>({
		resolver: registrationSchemeResolver,
		defaultValues,
	});
	
	const {
		register,
		handleSubmit,
		setError,
		setValue,
		formState: { errors },
		control,
		watch,
	} = form;

	const { store } = useSelector((state) => state.bcStore);

	useEffect(() => {
		if (store) {
			setValue("phone", store.phonePrefix);
		}
	}, []);

	const countryCode = watch("country_code");

	const pageContent = usePageContent();
	const dataLayer = useDataLayer();
	const klaviyo = useKlaviyo();

	const { t } = useTranslation();

	const [registerTrigger, registerResult] = useRegisterMutation();
	const [createKlaviyoProfile] =
		klaviyoApiEndpoints.useKlaviyoCreateProfileMutation();

	const [errorMessage, setErrorMessage] = useState<string[]>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const login = useLogin();

	const submitJoinLoyaltyForm = useJoinLoyaltyForm();

	const onSubmit: SubmitHandler<RegistrationFormScheme> = async (formData) => {
		setErrorMessage([]);
		setIsSubmitting(true);

		dataLayer.formSubmission("Registration Form");

		const response = await registerTrigger(formData)
			.unwrap()
			.then((data) => {
				if (formData.loyaltyProgram) {
					const payload = {
						email: formData.email,
						name: {
							first: formData.firstName,
							last: formData.lastName,
						},
					};

					submitJoinLoyaltyForm(payload);
				}
				

				return data;
			})
			.catch((err) => {
				switch (err.type) {
					case "fail_validation":
						for (const [name, errors] of Object.entries(err.data)) {
							const message = errors as any;
							if (message) {
								setError(name as any, { message: message[0] as string });
							}
						}
						break;
					case "account_exists":
						let accountTypeMessage = "";

						const emailError = `${t("form.registerEmailInUse")}: ${err.email}`;

						if (err.accountType === "email") {
							accountTypeMessage = t("form.registerErrorEmail");
						} else {
							accountTypeMessage = `${t(
								"form.registerErrorPlease",
							)} ${capitalize(err.accountType)} ${t(
								"form.registerErrorAccount",
							)}`;
						}

						setErrorMessage([emailError, accountTypeMessage]);
						break;
					default:
						setErrorMessage([err.message || ""]);
						break;
				}

				pageContent.scrollToTop();

				return undefined;
			});

		if (response?.success) {
			const createKlaviyoProfileArgs = {
				email: formData.email,
				first_name: formData.firstName,
				last_name: formData.lastName,
				external_id: `${response.data.customer.id}`,
				properties: {
					product_preference: formData.newsletterPreference,
				},
			};

			await createKlaviyoProfile({
				data: createKlaviyoProfileArgs,
				identifier: response.data.customer.identifier,
			});

			if (formData.newsletterPreference !== "") {
				await klaviyo.subscribe(response.data.customer.identifier);
			}

			login(response?.data);
		}

		setIsSubmitting(false);
	};

		let promoCode = []
		try{	
			const promoCodeKey = t("promoCode.rules");
			 promoCode = JSON.parse(promoCodeKey)
		}
		catch(error){
			console.error("Error parsing JSON:", error);
		}

	return (
		<Fragment>
			{errorMessage && (
				<Alert
					type={AlertType.ERROR}
					title={t("pageErrorTitle") as string}
					messages={errorMessage}
				/>
			)}
			<div style={{
				display:"flex",
				flexDirection: "column",
				alignItems: "flex-start"
			}}>
				{promoCode.map((promo: any, index: number) => (
					<p style={{color: "black"}} key={index}>{promo.content}</p>
				))}
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<FormSection>
					<SectionTitle>{t("your_details")}</SectionTitle>
					<InputRow>
						<InputText
							label={t("form.registerFirstName") as string}
							required
							{...register("firstName")}
							errorMsg={
								errors.firstName?.message &&
								(t(errors.firstName.message) as string)
							}
						/>
						<InputText
							label={t("form.registerLastName") as string}
							required
							{...register("lastName")}
							errorMsg={
								errors.lastName?.message &&
								(t(errors.lastName.message) as string)
							}
						/>
					</InputRow>
					<InputRow>
						<InputEmail
							label={t("form.signInEmail") as string}
							required
							{...register("email")}
							errorMsg={
								errors.email?.message && (t(errors.email.message) as string)
							}
						/>
						<InputPhoneNumber
							label={t("form.registerPhone") as string}
							required
							{...register("phone")}
							errorMsg={
								errors.phone?.message && (t(errors.phone.message) as string)
							}
							countryCode={countryCode}
						/>
					</InputRow>
				</FormSection>

				<AddressSection form={form} />

				<FormSection>
					<SectionTitle>{t("form.signInPassword")}</SectionTitle>
					<SectionText>{t("form.registerPasswordNotice")}</SectionText>
					<InputRow>
						<InputPassword
							label={t("form.signInPassword") as string}
							required
							{...register("password")}
							errorMsg={
								errors.password?.message &&
								(t(errors.password.message) as string)
							}
							autoComplete="new-password"
						/>
						<InputPassword
							label={t("form.registerConfirmPassword") as string}
							required
							{...register("passwordConfirmation")}
							errorMsg={
								errors.passwordConfirmation?.message &&
								(t(errors.passwordConfirmation.message) as string)
							}
							autoComplete="new-password"
						/>
					</InputRow>
				</FormSection>

				<FormSection>
					<SectionTitle>{t("profile.preferences")}</SectionTitle>
					<InputCol>
						<Controller
							control={control}
							name="privacyPolicy"
							render={({ field }) => (
								<InputCheckbox
									{...field}
									errorMsg={
										errors.privacyPolicy?.message &&
										(t(errors.privacyPolicy.message) as string)
									}
									label={
										<>
											{t("form.registerAgree")}{" "}
											<RegistrationFormLink to={t(PathTranslationKey.PRIVACY_POLICY).toString()}>
												{t("form.registerPolicy")}
											</RegistrationFormLink>
										</>
									}
								/>
							)}
						/>
						<Controller
							control={control}
							name="newsletterPreference"
							render={({ field }) => (
								<NewsLetterSubscribe onChange={field.onChange} />
							)}
						/>
						<Controller
							control={control}
							name="loyaltyProgram"
							render={({ field }) => (
								<InputCheckbox
									{...field}
									label={
										<>
											{t("form.registerJoinThe")}{" "}
											<RegistrationFormLink to={t(PathTranslationKey.LOYALTY_INFORMATION_PAGE).toString()}>
												{t("form.registerLoyaltyProgram")}
											</RegistrationFormLink>
										</>
									}
								/>
							)}
						/>
					</InputCol>
				</FormSection>

				<Button variant="primary_01" type="submit" loading={isSubmitting}>
					{t("form.registerCreateAccount")}
				</Button>
			</Form>
			<SigninSection>
				<SectionText>
					{t("form.registerAlreadyHaveAccount")}{" "}
					<RegistrationFormLink to={t(PathTranslationKey.SIGN_IN).toString()}>
						{t("form.signInSignIn")}
					</RegistrationFormLink>
				</SectionText>
			</SigninSection>
		</Fragment>
	);
};

export default RegistrationForm;
