import { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import customerApi from "app/api/customer/customerApi";

import { InputPassword, InputText } from "components/common/form/Input/Input";
import Button from "components/common/ui/Button/Button";
import IconEye from "components/common/ui/Icons/IconEye/IconEye";
import IconClosedEye from "components/common/ui/Icons/IconEye/IconClosedEye";

import removeEmptyKeys from "utilities/removeEmptyKeys";

import {
	profileFormSchema,
	ProfileFormSchema
} from "./ProfileForm.Schema";

import { AlertType } from "components/common/ui/Alert/Alert";
import {
	ButtonWrapper,
	StyledAlert,
	StyledForm,
	StyledInputCheckbox,
	StyledRadioGroup,
} from "./ProfileForm.Styled";
import { klaviyoProfile } from "app/types/klaviyo";
import useKlaviyo from "hooks/useKlaviyo";
import { useTranslation } from "react-i18next";
import DeleteAccount from "components/customer/DeleteAccount/DeleteAccount";

type ProfileKey = "first_name" | "last_name" | "email" | "phone";

interface Props {
	profile?: ProfileFormSchema | null;
	klaviyoProfile?: klaviyoProfile;
	onSubmit?: () => void;
}

const ProfileForm = ({
	profile,
	klaviyoProfile,
	onSubmit: onSubmitProp,
}: Props) => {
	const defaultValues = useMemo(() => {
		const values = new Map();

		if (profile) {
			Object.entries(profile).forEach(([key, value]) => {
				if (key in profileFormSchema.fields) {
					values.set(key as ProfileKey, String(value));
				}
			});
		}

		if (klaviyoProfile) {
			values.set(
				"newsletter_subscribe",
				klaviyoProfile.marketingPreferences.isSubscribed,
			);
			values.set(
				"newsletter_preference",
				klaviyoProfile.marketingPreferences.preference,
			);
		}

		return Object.fromEntries(values);
	}, [profile, klaviyoProfile]);

	const {
		control,
		formState,
		handleSubmit,
		register,
		reset,
		setError,
		setValue,
		watch,
	} = useForm<ProfileFormSchema>({
		resolver: yupResolver(profileFormSchema),
		defaultValues: defaultValues,
	});

	const klaviyo = useKlaviyo();

	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

	// Populate fields
	useEffect(() => {
		setValue("newsletter_subscribe", false);
		if (profile) {
			Object.entries(profile).forEach(([key, value]) => {
				if (key in profileFormSchema.fields) {
					setValue(key as ProfileKey, String(value));
				}
			});
		}

		if (klaviyoProfile) {
			setValue(
				"newsletter_subscribe",
				klaviyoProfile.marketingPreferences.isSubscribed,
			);
			setValue(
				"newsletter_preference",
				klaviyoProfile.marketingPreferences.preference,
			);
		}
	}, [profile, klaviyoProfile, setValue]);

	const [updateProfile, updateProfileResult] =
		customerApi.useUpdateProfileMutation();

	const onSubmit: SubmitHandler<ProfileFormSchema> = async (data) => {
		data = removeEmptyKeys(data);

		const success = await updateProfile(data)
			.unwrap()
			.then(() => {
				return true;
			})
			.catch((error) => {
				// Set error fields from API
				const validationError = error?.data?.error?.data?.validation;

				if (!validationError) return;

				Object.keys(validationError).forEach((error) => {
					setError(error as ProfileKey, {
						message: validationError[error]?.[0],
					});
				});

				return false;
			});

		const klaviyoUpdates = [];

		if (success) {
			klaviyoUpdates.push(
				klaviyo.updateNewsletterPreference(data.newsletter_preference),
			);

			if (data.newsletter_subscribe) {
				klaviyoUpdates.push(klaviyo.subscribe());
			} else {
				klaviyoUpdates.push(klaviyo.unsubscribe());
			}
		}

		if (klaviyoUpdates.length) {
			await Promise.all(klaviyoUpdates);
		}

		setShowSuccessMessage(true);
		setTimeout(() => {
			setShowSuccessMessage(false);
		}, 3000);

		reset();
		onSubmitProp?.();
	};

	const { t } = useTranslation();

	return (
		<div>
			{showSuccessMessage && (
				<StyledAlert
					type={AlertType.SUCCESS}
					title={t("success").toString()}
					messages={[t("profile.update.success").toString()]}
				/>
			)}
			<StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="new-password">
				<h4 className="form-heading">{t("profileForm.heading")}</h4>
				<div className="form-section">
					<div className="form-row">
						<InputText
							label={t("form.registerFirstName") as string}
							errorMsg={
								formState.errors.first_name?.message &&
								(t(formState.errors.first_name?.message) as string)
							}
							required
							{...register("first_name")}
						/>
						<InputText
							label={t("form.registerLastName") as string}
							errorMsg={
								formState.errors.last_name?.message &&
								(t(formState.errors.last_name?.message) as string)
							}
							required
							{...register("last_name")}
						/>
					</div>
					<div className="form-row">
						<InputText
							label={t("form.signInEmail") as string}
							errorMsg={
								formState.errors.email?.message &&
								(t(formState.errors.email?.message) as string)
							}
							required
							autoComplete="off"
							{...register("email")}
						/>
						<InputText
							label={t("form.registerPhone") as string}
							errorMsg={
								formState.errors.phone?.message &&
								(t(formState.errors.phone?.message) as string)
							}
							required
							{...register("phone")}
						/>
					</div>
				</div>
				<h4 className="form-heading">{t("profile.updatePassword")}</h4>
				<p className="form-description">{t("profile.updatePasswordNotice")}</p>
				<div className="form-section">
					<div className="form-row">
						<InputPassword
							label={t("form.currentPassword") as string}
							errorMsg={
								formState.errors.current_password?.message &&
								(t(formState.errors.current_password.message) as string)
							}
							autoComplete="new-password"
							{...register("current_password")}
						/>
					</div>
					<div className="form-row">
						<InputPassword
							label={t("form.newPassword") as string}
							errorMsg={
								formState.errors.password?.message &&
								(t(formState.errors.password.message) as string)
							}
							autoComplete="new-password"
							{...register("password")}
						/>
						<InputPassword
							label={t("form.confirmPassword") as string}
							errorMsg={
								formState.errors.password_confirmation?.message &&
								(t(formState.errors.password_confirmation.message) as string)
							}
							autoComplete="new-password"
							{...register("password_confirmation")}
						/>
					</div>
				</div>
				<h4 className="form-heading">{t("profile.preferences")}</h4>
				<div className="form-section">
					<div className="form-row">
						<div className="form-column">
							<p className="form-description">{t("profile.interests")}</p>
							<Controller
								control={control}
								name="newsletter_preference"
								render={({ field }) => (
									<StyledRadioGroup
										{...field}
										values={["Women", "Men", "Both"]}
									/>
								)}
							/>
						</div>
					</div>
					<Controller
						control={control}
						name="newsletter_subscribe"
						render={({ field }) => (
							<StyledInputCheckbox
								{...field}
								label={t("form.registerSubscribeNewsletter") as string}
								errorMsg={
									formState.errors.newsletter_subscribe?.message &&
									(t(formState.errors.newsletter_subscribe?.message) as string)
								}
							/>
						)}
					/>
				</div>
				<ButtonWrapper>
					<Button
						type="submit"
						variant="primary_01"
						disabled={updateProfileResult.isLoading}
						loading={updateProfileResult.isLoading}
					>
						{t("profile.updateDetailsButton")}
					</Button>
					<DeleteAccount />
				</ButtonWrapper>

			</StyledForm>
		</div>
	);
};

export default ProfileForm;
