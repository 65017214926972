import useIosTrackingConsent from "hooks/useIosTrackingConsent";
import { useEffect, useState } from "react";
import { useSelector } from "redux/hooks";
import store from "redux/store";
import { PageType } from "ts/enums";
import { Product } from "ts/types";
import { Cart, PhysicalItem } from "types/CartTypes";
import { number } from "yup";

export enum DatalayerEvent {
	PAGE_VIEW = "page_view",
	VIEW_ITEM_LIST = "view_item_list",
	SELECT_ITEM = "select_item",
	VIEW_ITEM = "view_item",
	ADD_TO_CART = "add_to_cart",
	ADD_TO_WISHLIST = "add_to_wishlist",
	REMOVE_FROM_WISHLIST = "remove_from_wishlist",
	VIEW_CART = "view_cart",
	REMOVE_FROM_CART = "remove_from_cart",
	BEGIN_CHECKOUT = "begin_checkout",
	ADD_SHIPPING_INFO = "add_shipping_info",
	ADD_PAYMENT_INFO = "add_payment_info",
	PURCHASE = "purchase",
	FORM_SUBMISSION = "form_submission",
}

export type ItemList = {
	id: string;
	name: string;
};

/**
 * Convert a Product to a data layer item
 * @param product to covert to data layer item
 * @param index index value
 * @param itemListId
 * @param itemListName
 * @returns
 */
const convertProduct = (
	product: Product,
	index: number,
	itemListId: string | number,
	itemListName: string,
) => {
	return {
		item_id: product.sku,
		item_name: product.name,
		currency: product.price.currencyCode,
		discount: 0,
		index: index,
		item_brand: "MONA",
		item_list_id: itemListId,
		item_list_name: itemListName,
		item_variant: "",
		price: product?.salePrice?.value || product?.basePrice?.value,
		quantity: 1,
	};
};

/**
 * Calculating value based on its quantity price and discount
 **/

const calculateItemValue = (quantity: number, item: any): number => {
	return quantity * (parseFloat(item.price) - parseFloat(item.discount))
}

const convertCartItems = (cart: Cart | undefined) => {
	const items = [] as any[];

	if (cart) {
		cart.line_items.physical_items.forEach((item, index) => {
			items.push({
				item_id: item.sku,
				item_name: item.name,
				currency: cart.currency.code,
				discount: item.discount_amount,
				index: index,
				item_brand: "",
				item_variant: item.variant_id,
				price: String(item.list_price),
				quantity: String(item.quantity),
			});
		});
	}

	return items;
};

const convertIndividualCartItem = (
	cartItem: PhysicalItem,
	currency: string,
	quantity: number = 1,
	isRemove: boolean = false,
	itemList: ItemList,
) => {
	let item: any = {
		item_id: cartItem.sku,
		item_name: cartItem.name,
		currency,
		discount: cartItem.discounts.reduce(
			(accumulator: any, discount: any) =>
				accumulator + discount.discounted_amount,
			0,
		),
		index: 0,
		item_brand: "",
		item_variant: cartItem.variant_id,
		price: String(
			cartItem.extended_list_price &&
				cartItem.extended_list_price !== cartItem.extended_sale_price
				? cartItem.extended_list_price
				: cartItem.extended_sale_price,
		),
		...(cartItem.extended_list_price &&
			cartItem.extended_list_price !== cartItem.extended_sale_price && {
			oldPrice: String(cartItem.extended_sale_price),
		}),
		quantity: quantity,
	};

	if (!isRemove) {
		item.item_list_id = itemList.id;
		item.item_list_name = itemList.name;
	}

	return [item];
};

const convertWishlistItem = (
	wishlistItem: Product,
	currency: string | undefined,
	quantity: number = 1,
) => {
	let item: any = {
		item_id: wishlistItem.sku,
		item_name: wishlistItem.name,
		currency,
		index: 0,
		price: wishlistItem.price,
		quantity,
	};

	return item;
};

const setupGTM = () => {
	const gtmScriptTag = document.querySelector("[data-gtm-script]");

	if (gtmScriptTag === null) {
		const scriptTag = document.createElement("script");
		scriptTag.dataset.gtmScript = "gtm-script";

		scriptTag.innerHTML = `(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != "dataLayer" ? "&l=" + l : "";
			j.async = true;
			j.src = "https://tag.monaonline.com/gtm.js?id=" + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, "script", "dataLayer", "GTM-WVK5N2T");`;

		document.head.appendChild(scriptTag);
	}
};

const useDataLayer = () => {
	const { auth, bcStore, location } = useSelector((state) => state);
	const trackingConsent = useIosTrackingConsent();

	const dataLayer = (window as any).dataLayer;
	const GTM = (window as any).google_tag_manager;

	const [isReady, setIsReady] = useState(GTM?.dataLayer?.gtmLoad !== undefined);
	const [queuedEvents, setQueuedEvents] = useState<any[] | undefined>([]);

	const checkReady = () => {
		const ready = GTM?.dataLayer?.gtmLoad !== undefined;

		if (!isReady && ready) {
			setIsReady(true);
		}
	};

	/*
			on initial load the gtmLoad fires after the last render meaning the isReady never
			changes and the queued events cant fire. there doesn't seem to be a way to listen
			for the gtmLoad event so instead we use an interval and clear it when the isReady
			changes
	*/
	const [gtmInterval] = useState<NodeJS.Timeout>(setInterval(checkReady, 300));

	useEffect(() => {
		setupGTM();
	}, []);

	useEffect(() => {
		if (isReady) {
			clearInterval(gtmInterval);
		}

		if (isReady && queuedEvents !== undefined) {
			if (queuedEvents.length) {
				const events = [...queuedEvents];

				setTimeout(() => {
					events.forEach((event) => dataLayer.push(event));
				}, 300);
			}

			setQueuedEvents(undefined);
		}
	}, [isReady]);

	/**
	 * Check to see if datalayer is ready and either queue the event or push to datalayer
	 * @param event to push
	 */
	const push = (event: any) => {
		if (!trackingConsent) return;

		if (!isReady) {
			setQueuedEvents((prev) => [...(prev || []), event]);
			checkReady();
		} else {
			dataLayer.push(event);
		}
	};

	const dataLayerEcommerce = (
		event: DatalayerEvent,
		items: any[] | string,
		currency: string | undefined,
		value: number | undefined,
	) => {
		push({
			ecommerce: null,
		}); // clear previous object

		push({
			event,
			ecommerce: {
				...(currency && { currency }),
				...(value && { value }),
				items,
			},
		});
	};

	const dataLayerEvent = (event: DatalayerEvent, formType: string) => {
		push({
			event,
			formType: formType,
		});
	};

	const pageView = async (pageType: PageType) => {
		const userId = auth.customer ? (auth.customer as any).id : null;
		const activeCurrency = bcStore.currencies.find(
			(currency) => currency.isActive,
		);

		let data: {
			event: string;
			currencyCode: string;
			language: string;
			userId: any;
			pageType: PageType;
			subCategory?: string;
		} = {
			event: "page_view",
			currencyCode: activeCurrency?.code || "RSD",
			language: location?.preferredLocation || "RS",
			userId,
			pageType: pageType,
		};

		push(data);
	};

	const viewItemList = (
		products: Product[],
		itemListId: string | number,
		itemListName: string,
	) => {
		const items = products.map((product, index) =>
			convertProduct(product, index, itemListId, itemListName),
		);
		dataLayerEcommerce(
			DatalayerEvent.VIEW_ITEM_LIST,
			items,
			undefined,
			undefined,
		);
	};

	const viewItem = (
		product: Product,
		itemListId: string | number,
		itemListName: string,
	) => {
		const item = [convertProduct(product, 0, itemListId, itemListName)];

		dataLayerEcommerce(DatalayerEvent.VIEW_ITEM, item, undefined, undefined);
	};

	const selectItem = (
		product: Product,
		itemListId: string | number,
		itemListName: string,
	) => {
		const items = [convertProduct(product, 0, itemListId, itemListName)];
		dataLayerEcommerce(DatalayerEvent.SELECT_ITEM, items, undefined, undefined);
	};

	const addToWishList = (
		product: Product,
		itemListId: string | number,
		itemListName: string,
	) => {
		const items = [convertProduct(product, 0, itemListId, itemListName)];
		dataLayerEcommerce(
			DatalayerEvent.ADD_TO_WISHLIST,
			items,
			calculateItemValue(1, items[0]).toString(),
			undefined,
		);

	};

	// remove from wishlist
	const removeWishListItem = (
		products: Product[],
		currency: string | undefined,
	) => {
		const items = products.map((product) =>
			convertWishlistItem(product, currency, 1),
		);

		dataLayerEcommerce(
			DatalayerEvent.REMOVE_FROM_WISHLIST,
			items,
			currency,
			undefined,
		);
	};

	const viewCart = (cart: Cart | undefined) => {
		const items = convertCartItems(cart);

		dataLayerEcommerce(
			DatalayerEvent.VIEW_CART,
			items,
			cart?.currency.code || undefined,
			cart?.cart_amount || undefined,
		);
	};

	const beginCheckout = (cart: Cart | undefined) => {
		const items = convertCartItems(cart);

		dataLayerEcommerce(
			DatalayerEvent.BEGIN_CHECKOUT,
			items,
			cart?.currency.code || undefined,
			cart?.cart_amount || undefined,
		);
	};

	const addToCart = (
		triggeredFrom: string,
		product: PhysicalItem & {
			parent_sku: string;
		},
		quantity: number,
		currency: string,
		itemList: ItemList,
	) => {
		const items = convertIndividualCartItem(
			product,
			currency,
			quantity,
			false,
			itemList,
		);

		dataLayerEcommerce(
			DatalayerEvent.ADD_TO_CART,
			items,
			currency,
			product.list_price,
		);
	};

	const removeFromCart = (
		triggeredFrom: string,
		product: PhysicalItem & {
			parent_sku: string;
		},
		quantity: number,
		currency: string,
		itemList: ItemList,
	) => {
		const items = convertIndividualCartItem(
			product,
			currency,
			quantity,
			true,
			itemList,
		);
		dataLayerEcommerce(
			DatalayerEvent.REMOVE_FROM_CART,
			items,
			currency,
			calculateItemValue(quantity, items[0]),
		);

	};

	const formSubmission = (event: string) => {
		const formType = event;
		dataLayerEvent(DatalayerEvent.FORM_SUBMISSION, formType);
	};

	const updateConsent = (
		marketing: boolean,
		stats: boolean,
		permanent: boolean,
	) => {
		const getValue = (value: boolean) => {
			return value ? "granted" : "denied";
		};

		push({
			event: "consent",
			update: {
				ad_storage: getValue(marketing),
				analytics_storage: getValue(stats),
				personalization_storage: getValue(permanent),
			},
		});
	};

	return {
		pageView,
		viewItemList,
		selectItem,
		viewCart,
		addToCart,
		removeFromCart,
		viewItem,
		addToWishList,
		removeWishListItem,
		formSubmission,
		updateConsent,
		beginCheckout,
	};
};

export default useDataLayer;
