import { useLazyGetCategoryViaSearchQuery } from "app/api/category/categoryApi";
import SortOptions from "components/plp/PlpSort/SortOptions";
import { useEffect, useState } from "react";
import { Category, Product } from "ts/types";

export interface FilterValues {
	label: string;
	value: string;
	count: number;
	isSelected: boolean;
	subCategories: FilterValues[];
}

export interface Filter {
	label: string;
	isCollapsedByDefault: boolean;
	__typename: string;
	values?: FilterValues[];
	maxPrice: string | number;
	minPrice: string | number;
}

const useCategoryPagination = (category: Category | undefined) => {
	const sessionStorageCache = JSON.parse(
		sessionStorage.getItem("category_cache") || "{}",
	);

	const [products, setProducts] = useState<Product[]>(
		sessionStorageCache?.products || [],
	);
	const [filters, setFilters] = useState<Filter[]>([]);
	const [pagination, setPagination] = useState<{
		endCursor: string;
		startCursor: string;
		hasNextPage: boolean;
		hasPreviousPage: boolean;
	}>(sessionStorageCache?.pagination || null);
	const [loading, setLoading] = useState(true);
	const [uninitialized, setUninitialized] = useState(!sessionStorageCache);

	const [nextProductSetCursor, setNextProductSetCursor] = useState("");
	const [concatNextProductSet, setConcatNextProductSet] = useState(false);

	const [toFilterAttributes, setToFilterAttributes] = useState<
		{
			attribute: string;
			values: string[];
		}[]
	>([]);
	const [toFilterCategoryIds, setToFilterCategoryIds] = useState<number[]>(
		[category?.entityId || 0].filter(Boolean),
	);
	const [toFilterMinPrice, setToFilterMinPrice] = useState<number>();
	const [toFilterMaxPrice, setToFilterMaxPrice] = useState<number>();

	const [productSortOrder, setProductSortOrder] = useState(SortOptions[0].id);

	const [trigger] = useLazyGetCategoryViaSearchQuery();

	useEffect(() => {
		if (loading && category && sessionStorageCache)
			if (sessionStorageCache.lastViewedCategory === category.entityId) {
				setLoading(false);
			}
	}, [sessionStorageCache]);

	useEffect(() => {
		if (
			category?.entityId &&
			sessionStorageCache.lastViewedCategory !== category?.entityId
		) {
			setUninitialized(false);
			setLoading(true);

			trigger({
				entityId: category.entityId,
				sort: productSortOrder,
			})
				.unwrap()
				.then((res) => {
					setProducts(res?.products);
					setFilters(res?.filters);
					setPagination(res?.pageInfo);

					sessionStorage.setItem(
						"category_cache",
						JSON.stringify({
							products: res?.products,
							filters: res?.filters,
							pagination: res?.pageInfo,
							lastViewedCategory: category?.entityId,
						}),
					);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [category]);

	useEffect(() => {
		if (category?.entityId) {
			setLoading(true);

			trigger({
				...(concatNextProductSet && {
					nextProductSetCursor,
				}),
				filterAttributes: toFilterAttributes,
				filterCategoryIds: toFilterCategoryIds.length
					? toFilterCategoryIds
					: Array.from(new Set([...toFilterCategoryIds, category.entityId])),
				...((toFilterMinPrice || toFilterMaxPrice) && {
					filterPrice: {
						...(toFilterMinPrice &&
							toFilterMinPrice > 0 && {
								minPrice: toFilterMinPrice,
							}),
						...(toFilterMaxPrice &&
							toFilterMaxPrice > 0 && {
								maxPrice: toFilterMaxPrice,
							}),
					},
				}),
				sort: productSortOrder,
			})
				.unwrap()
				.then((res) => {
					if (concatNextProductSet) {
						setProducts(products.concat(res?.products));
					} else {
						setProducts(res?.products);
					}

					setFilters(res?.filters);
					setPagination(res?.pageInfo);

					sessionStorage.setItem(
						"category_cache",
						JSON.stringify({
							products: concatNextProductSet
								? products.concat(res?.products)
								: res?.products,
							filters: res?.filters,
							pagination: res?.pageInfo,
							lastViewedCategory: category?.entityId,
						}),
					);
				})
				.catch(() => {
					// setUninitialized(false);
					// setLoading(false);
					// setConcatNextProductSet(false);
				})
				.finally(() => {
					setUninitialized(false);
					setLoading(false);
					setConcatNextProductSet(false);
				});
		}
	}, [
		toFilterAttributes,
		toFilterCategoryIds,
		toFilterMinPrice,
		toFilterMaxPrice,
		productSortOrder,
		nextProductSetCursor,
	]);

	const getNextProductSet = () => {
		if (pagination?.endCursor) {
			setNextProductSetCursor(pagination.endCursor);
			setConcatNextProductSet(true);
		}
	};

	const filterProductsByAttribute = (
		filters: {
			attribute: string;
			values: string[];
		}[],
	) => {
		setToFilterAttributes(filters);
	};

	const filterProductsByCategoryIds = (ids: number[]) => {
		setToFilterCategoryIds([...ids, category?.entityId || 0].filter(Boolean));
	};

	const filterProductsByPrice = (
		minPrice: number | undefined,
		maxPrice: number | undefined,
	) => {
		if (typeof minPrice !== "undefined" || (minPrice && minPrice > 0)) {
			setToFilterMinPrice(minPrice);
		}

		if (typeof maxPrice !== "undefined" || (maxPrice && maxPrice > 0)) {
			setToFilterMaxPrice(maxPrice);
		}
	};

	const setSortOrder = (sort: string) => {
		setProductSortOrder(sort);
	};

	const clearFilters = () => {
		setToFilterCategoryIds([category?.entityId || 0].filter(Boolean));
		setToFilterAttributes([]);
		setToFilterMaxPrice(0);
		setToFilterMinPrice(0);
	};

	return {
		uninitialized,
		loading,
		products,
		filters,
		pagination,
		clearFilters,
		getNextProductSet,
		filterProductsByAttribute,
		filterProductsByCategoryIds,
		filterProductsByPrice,
		setSortOrder,
	};
};

export default useCategoryPagination;
